import { ref, watch } from '@vue/composition-api'
import store from '@/store'

export default function useSelectInput(props) {
  const options = ref([])

  const fetchData = () => {
    store.dispatch('select-input/fetchData', props)
      .then(response => {
        const { data } = response.data
        options.value = data
      })
      .catch(() => {
        options.value = []
      })
  }

  watch(() => props.apiId, () => {
    fetchData()
  })

  return {
    options,
    fetchData,
  }
}
