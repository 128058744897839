<template>
  <validation-provider
    #default="validationContext"
    :name="label"
  >
    <b-form-group
      :label="label"
      :label-for="id"
      :class="classess"
    >
      <v-select
        :id="id"
        :options="options"
        :value="value"
        :clearable="clearable"
        :get-option-label="getOptionLabel"
        :reduce="reduce"
        @input="(val) => {
          $emit('update:value', val)
        }"
      />
      <b-form-invalid-feedback :state="getValidationState(validationContext)">
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import {
  BFormGroup,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { onUnmounted } from '@vue/composition-api'
import selectInputStoreModule from './selectInputStoreModule'
import useSelectInput from './useSelectInput'
import store from '@/store'

export default {
  components: {
    BFormGroup,
    BFormInvalidFeedback,
    ValidationProvider,
    vSelect,
  },
  props: {
    // options: {
    //   type: Array,
    //   required: false,
    //   default: () => [],
    // },
    value: {
      type: [Object, String, null],
      default: null,
    },
    apiId: {
      type: [Object, String, null],
      default: null,
    },
    api: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    getOptionLabel: {
      type: Function,
      required: false,
    },
    reduce: {
      type: Function,
      required: false,
    },
    classess: {
      type: String,
      required: false,
      default: '',
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const STORE_MODULE_NAME = 'select-input'
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, selectInputStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      getValidationState,
    } = formValidation(() => {})

    const {
      options,
      fetchData,
    } = useSelectInput(props)

    if (props.api) {
      fetchData()
    }

    return {
      options,
      getValidationState,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
