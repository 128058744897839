import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // eslint-disable-next-line
    async fetchData(context, payload) {
      const url = payload.apiId ? `${payload.api}/${payload.apiId}` : payload.api

      return axios.get(url, {
        params: payload.params,
      })
    },
    /* eslint-disable */
    async execAction(context, payload) {
      const url = payload.apiId ? `${payload.api}/${payload.apiId}` : payload.api

      if (payload.alert) {
        // eslint-disable-next-line no-underscore-dangle
        return this._vm.$swal({
          text: `${payload.label.replace('{id}', payload.apiId)}?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Да',
          cancelButtonText: 'Нет',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          showLoaderOnConfirm: true,
          preConfirm() {
            return axios.get(url, {
              params: payload.params,
            })
              .then(res => res)
              .catch(err => err.response)
          },
        }).then(result => {
          if (result.isConfirmed) {
            const { data, success } = result.value.data
            if (success) {
              // eslint-disable-next-line no-underscore-dangle
              return this._vm.$swal({
                icon: 'success',
                text: 'Операция успешно выполнена!',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              }).then(() => true)
            } else {
              // eslint-disable-next-line no-underscore-dangle
              return this._vm.$swal({
                icon: 'error',
                text: data.msg,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              }).then(() => false)
            }
          }
        })
      } else if (payload.name === 'Comment') {
        // eslint-disable-next-line no-underscore-dangle
        return this._vm.$swal({
          text: payload.label.replace('{id}', payload.apiId),
          icon: 'question',
          input: 'textarea',
          inputPlaceholder: 'Текст сообщения',
          showCancelButton: true,
          confirmButtonText: 'Отправить',
          cancelButtonText: 'Отмена',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
            input: 'mt-1',
          },
          buttonsStyling: false,
          showLoaderOnConfirm: true,
          preConfirm(message) {
            return axios.put(`${url}?xparam1=${message}&xparam2=1`)
          },
        }).then(result => {
          if (result.isConfirmed) {
            const { data, success } = result.value.data
            if (data) {
              if (!success) {
                // eslint-disable-next-line no-underscore-dangle
                return this._vm.$swal({
                  icon: 'error',
                  text: data.msg,
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                }).then(() => false)
              } else {
                // eslint-disable-next-line no-underscore-dangle
                return this._vm.$swal({
                  icon: 'success',
                  text: data.msg,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                }).then(() => true)
              }
            } else {
              // eslint-disable-next-line no-underscore-dangle
              this._vm.$swal({
                icon: 'error',
                text: 'Возникла непредвиденная ошибка. Попробуйте еще раз',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              }).then(() => false)
            }
          }
        })
      } else if (payload.name === 'Status') {
        const res = await axios.get('https://api.hoglabest.com/api/v1/json/cp/service/data/list/invStatusSet')
        // eslint-disable-next-line
        const { data, success } = res.data
        let options = ''
        if (success && data && data.length > 0) {
          data.forEach(item => {
            options += `<option value="${item.value}">${item.text}</option>`
          })
        }
        // eslint-disable-next-line no-underscore-dangle
        return this._vm.$swal({
          icon: 'question',
          html: `<div id="swal2-content" class="swal2-html-container" style="display: block;">${payload.label.replace('{id}', payload.apiId)}?</div><select id="select-status" class="custom-select custom-select-lg mt-1">${options}</select>`
          + '<textarea id="select-msg" placeholder="Текст сообщения" rows="3" wrap="soft" class="swal2-textarea mt-1"></textarea>',
          showCancelButton: true,
          confirmButtonText: 'Отправить',
          cancelButtonText: 'Отмена',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          showLoaderOnConfirm: true,
          preConfirm() {
            const status = document.getElementById('select-status').value
            const msg = document.getElementById('select-msg').value
            return axios.put(`${url}?xparam1=${msg}&xparam2=${status}`)
          },
        }).then(result => {
          if (result.isConfirmed) {
            // eslint-disable-next-line
            const { data, success } = result.value.data
            if (data) {
              if (!success) {
                // eslint-disable-next-line no-underscore-dangle
                return this._vm.$swal({
                  icon: 'error',
                  text: data.msg,
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                }).then(() => false)
              } else {
                // eslint-disable-next-line no-underscore-dangle
                return this._vm.$swal({
                  icon: 'success',
                  text: data.msg,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                }).then(() => true)
              }
            } else {
              // eslint-disable-next-line no-underscore-dangle
              return this._vm.$swal({
                icon: 'error',
                text: 'Возникла непредвиденная ошибка. Попробуйте еще раз',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              }).then(() => false)
            }
          }

          return false
        })
      } else {
        return axios.get(url, {
          params: payload.params,
        })
      }
    },
  },
}
