        <template>
  <b-card>
    <b-row
          >
                                    <b-col
                                                                                      sm="6"
                                        >
                            <select-input
  id="structCP"
  name="structCP"
    :value.sync="xparam1"
        :options="[]"
      :getOptionLabel="option =&gt; option.text"
      :reduce="val =&gt; val.value"
            label="Период"
  api="https://api.hoglabest.com/api/v1/json/lk/service/data/list/structCP"
  />            </b-col>
                                        <b-col
                                                                                      sm="6"
                                        >
              <div
                                class="d-flex justify-content-end pt-2"
                              >
                                  <div
                                        >
                                            <refresh-button
  label="Обновить"
  :refresh.sync="refresh"
    variant="outline-primary"
        classes="ml-50"
  />                  </div>
                              </div>
            </b-col>
                                        <b-col
                                                                                      sm="12"
                                        >
                            <basic-table
  :columns="[{&quot;key&quot;:&quot;id_num&quot;,&quot;sortable&quot;:true,&quot;label&quot;:&quot;\u2116\u043f\u043f&quot;,&quot;type&quot;:&quot;text&quot;,&quot;thClass&quot;:&quot;text-center&quot;,&quot;tdClass&quot;:&quot;text-right&quot;},{&quot;key&quot;:&quot;FIO&quot;,&quot;sortable&quot;:true,&quot;label&quot;:&quot;\u0424\u0418\u041e&quot;,&quot;type&quot;:&quot;text&quot;,&quot;thClass&quot;:&quot;text-center&quot;},{&quot;key&quot;:&quot;Sponsor&quot;,&quot;sortable&quot;:true,&quot;label&quot;:&quot;\u041f\u043e\u0434 \u043a\u0435\u043c \u0441\u0442\u043e\u0438\u0442&quot;,&quot;type&quot;:&quot;text&quot;,&quot;thClass&quot;:&quot;text-center&quot;},{&quot;key&quot;:&quot;town_name&quot;,&quot;sortable&quot;:true,&quot;label&quot;:&quot;\u0413\u043e\u0440\u043e\u0434&quot;,&quot;type&quot;:&quot;text&quot;,&quot;thClass&quot;:&quot;text-center&quot;},{&quot;key&quot;:&quot;Status&quot;,&quot;sortable&quot;:true,&quot;label&quot;:&quot;\u0421\u0442\u0430\u0442\u0443\u0441&quot;,&quot;type&quot;:&quot;text&quot;},{&quot;key&quot;:&quot;selfBall&quot;,&quot;sortable&quot;:true,&quot;label&quot;:&quot;\u0412\u0445\u043e\u0434&quot;,&quot;type&quot;:&quot;float&quot;,&quot;thClass&quot;:&quot;text-center&quot;,&quot;tdClass&quot;:&quot;text-right&quot;},{&quot;key&quot;:&quot;selfCnt&quot;,&quot;sortable&quot;:true,&quot;label&quot;:&quot;\u0411\u0430\u043b\u043b\u044b&quot;,&quot;type&quot;:&quot;float&quot;,&quot;thClass&quot;:&quot;text-center&quot;,&quot;tdClass&quot;:&quot;text-right&quot;},{&quot;key&quot;:&quot;struct&quot;,&quot;sortable&quot;:true,&quot;label&quot;:&quot;\u0412\u0435\u0442\u043a\u0430&quot;,&quot;type&quot;:&quot;text&quot;,&quot;thClass&quot;:&quot;text-center&quot;,&quot;tdClass&quot;:&quot;text-center&quot;}]"
          :refresh="refresh"
        :xparam1="xparam1"
                                api="https://api.hoglabest.com/api/v1/json/lk/buyer/lp/grid/allbuyer"
          />            </b-col>
                            </b-row>
  </b-card>
</template>
<script>
/* eslint-disable */
// prettier-ignore
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'

import { ref } from '@vue/composition-api'

                                      import SelectInput from '@core/components/input/select-input/SelectInput.vue'
                                                                            import RefreshButton from '@core/components/button/RefreshButton.vue'
                                                                          import BasicTable from '@core/components/table/basic-table/BasicTable.vue'
                            
export default {
  components: {
    BCard,
    BRow,
    BCol,

                                                          SelectInput,
                                                                                              RefreshButton,
                                                                                            BasicTable,
                                          },
  setup() {
                const xparam1 = ref('0')
            const refresh = ref(false)
          
          return {
            xparam1,
            refresh,
            }
      },
}
</script>
    